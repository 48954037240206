
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import { UserClient, HomeClient } from '@/services/Services';
// import ConversationVm from '@/components/conversationVm.vue';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Options({
    components: {
        // ConversationVm
    }
})
export default class GlobalSearch extends Vue {

    community: string = VM.SearchResultTypeVm.Community;
    event: string = VM.SearchResultTypeVm.Event;
    fiera: string = VM.SearchResultTypeVm.Fiera;
    topic: string = VM.SearchResultTypeVm.Topic;
    post: string = VM.SearchResultTypeVm.Post;
    shop: string = VM.SearchResultTypeVm.Shop;
    loading: boolean = true;
    search: string = "";
    results: OM.SearchResultVm[] = [];

    beforeRouteEnter(to, from, next){
        next((vc: GlobalSearch) => {
            vc.search = StorageServices.getGlobalSearch();
            vc.results = StorageServices.getGlobalSearchResults();
        })
    }

    mounted(){
        (<any>this.$refs.searchInput).focus();
        this.loading = false;
    }

    
    doSearch(){
        if(this.search.length < 2)
            return;

        this.loading = true;
        HomeClient.globalSearch(this.search)
        .then(x => {
            this.results = x;
        })
        .finally(() => this.loading = false);
    }

    openItem(item: OM.SearchResultVm){
        StorageServices.setGlobalSearch(this.search);
        StorageServices.setGlobalSearchResults(this.results);

        if(item.isTopic)
            this.goToTopic(item);
        else if(item.isProduct)
            this.goToProduct(item);
        else if(item.isPost)
            this.goToPost(item)
        else if(item.type == VM.SearchResultTypeVm.Community)
            this.$router.push('/communities/' + item.slug);
        else if(item.type == VM.SearchResultTypeVm.Event)
            this.$router.push('/events/' + item.slug);
        else if(item.type == VM.SearchResultTypeVm.Fiera) {
            this.$router.push('/fiera/' + item.slug);
        }
    }

    goToTopic(item: OM.SearchResultVm){
        store.state.selectedTagFromSearch = item.tag;

        if(item.type == VM.SearchResultTypeVm.Community)
            this.$router.push('/communities/' + item.parentSlug + "/forum");
        else if(item.type == VM.SearchResultTypeVm.Event)
            this.$router.push('/events/' + item.parentSlug + "/forum");
        else if(item.type == VM.SearchResultTypeVm.Fiera)
            this.$router.push('/fiera/forum/' + item.parentSlug);
    }

    goToPost(item: OM.SearchResultVm){
        var path = "Bacheca";

        if(item.isForumPost)
            path = "forum";

        if(item.type == VM.SearchResultTypeVm.Community)
            this.$router.push('/communities/' + item.parentSlug + "/" + path + "?f=" + item.identifier);
        else if(item.type == VM.SearchResultTypeVm.Event)
            this.$router.push('/events/' + item.parentSlug + "/" + path + "?f=" + item.identifier);
        else if(item.type == VM.SearchResultTypeVm.Fiera)
            this.$router.push('/fiera/' + path + '/' + item.parentSlug + "?f=" + item.identifier);
    }

    goToProduct(item: OM.SearchResultVm){
        if(item.type == VM.SearchResultTypeVm.Community)
            this.$router.push('/communities/' + item.parentSlug + "/shop?f=" + item.identifier);
        else if(item.type == VM.SearchResultTypeVm.Event)
            this.$router.push('/events/' + item.parentSlug + "/shop?f=" + item.identifier);
        else if(item.type == VM.SearchResultTypeVm.Fiera)
            this.$router.push('/fiera/' + item.parentSlug + "/shop?f=" + item.identifier);
    }

    debouncer: any = null;
    debouncia($event: any){
        this.search = $event.target.value;
        
        clearTimeout(this.debouncer);

        this.debouncer = setTimeout(() => {
            this.doSearch();
        }, 300);
    }
}

